<template>
  <div class="content">
    <el-menu
      :default-openeds="[$store.state.title]"
      class="el-menu-vertical-demo menu"
      :unique-opened="true"
      @select="handleSelect"
    >
      <!-- :default-active="[$store.state.name]" -->
      <el-submenu
        v-for="(item, index) in list"
        :index="item.title"
        :key="index"
      >
        <template slot="title">
          <div style="padding-left: 3rem">
            <span style="font-size: 2rem">{{ item.title }}</span>
          </div>
        </template>
        <el-menu-item
          v-for="(text, key) in item.children"
          :index="text.title"
          :key="key"
		  style='padding-left:0rem;'
        >
          <div class="son-title">
            <div
              :class="`son-text ${
                text.title === activeTitle ? 'son-text-active' : ''
              }`"
			  :title="text.title"
            >
              {{ text.title }}
            </div>
          </div>
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import store from "@/store";
export default {
  data() {
    return {
      inTitle: "智慧政务",
      activeTitle: "",
      list: [
        {
          title: "智慧政务",
          children: [
            {
              title: "全国人民代表大会某委员会",
              text1: [
                "屏蔽机房系统工程（装修系统、空调新风系统、门禁系统、电源防雷系统、弱电系统、电视监控系统、漏水监测报警系统、场地环境监控系统、视频会议室系统、供配电及UPS不间断电源系统、消防系统 ）",
              ],
              src: "全国人民代表大会某委员会1.png",
            },
            {
              title: "中共某省委员会办公厅",
              text: [
                "应急指挥系统 2014年新建：应急指挥中心、多媒体显示系统、多媒体音视频系统集成；与省公安厅、信访局、武汉市公安局、省高速进行视频对接；并与17个市级行政总值班室实现音视频互联互通。2016年升级改造：与省政府、省军区、省武警总队、省水利厅、气象局等实现音视频互联互通，为历年的抗洪抢险总指挥部，领导通过应急指挥系统会商、指挥防汛工作。获得了领导的高度好评。",
                "屏蔽机房系统工程（装修系统、空调新风系统、门禁系统、电源防雷系统、弱电系统、电视监控系统、漏水监测报警系统、场地环境监控系统、供配电及UPS不间断、电源系统、消防系统 ）",
                "国贫县信息化提升工程",
                "身份认证系统（定制开发）",
                "运维服务（机房全系统、会议全系统、网络系统、身份认证系统等运行保障）",
              ],
              src: "中共某省委员会办公厅1.png",
            },
            {
              title: "某省人民政府",
              text1: [
                "基千反恐和场规模式下的综合防控系统，建设智慧综合安防管理平台，实现人、车无障碍通行的适时识别控制管理，并负责省政府大院安防系统运行维护；本项目的试运行阶段，参加了湖北省军区的反恐应急军演，受到省、市各级领导好评。荣获2016年武汉市安防系统典型示范工程。",
              ],
              src: "某省人民政府1.png",
            },
            {
              title: "中共某省委组织部",
              text: [
                "网络系统集成、视频会议及档案管理系统工程（音视频显示及扩声系统、会议录播系统、办公自动系统、干部管理信息系统、干部档案管理系统及数字化加工、LED大屏幕显示系统、会议室家具）",
                "运行维护（内外网中电子设备、视频会议全系统、档案管理系统等运行保障）",
              ],
              src: "中共某省委组织部1.png",
            },
            {
              title: "中共某省委宣传部",
              text1: [
                "建筑智能化系统、停车场管理系统、楼宇控制系统、综合安防系统、会议室及多媒体系统、消防系统涉密中心；数据中心机房、屏蔽室机房",
              ],
              src: "中共某省委宣传部.png",
            },
            {
              title: "某省国家安全厅",
              text1: [
                "建筑智能化弱电系统、涉密机房系统工程（结构装饰装修系统、供配电系统系统、照明及应急照明系统、接地系统、防雷系统、UPS不间断电源系统、消防报警系统、气体自动灭火系统、环境监控系统、电视监控系统、门禁系统）、多功能会议室系统",
              ],
              src: "某省国家安全厅、某市安全局、某州安全局 、某地市安全局1.png",
            },
            {
              title: "某省公安厅",
              text1: ["大数据中心机房、信患系统集成、综合安防系统。"],
              src: "某省公安厅1.png",
            },
            {
              title: "湖北省司法厅",
              text: [
                "建筑智能化布线系统、多媒体会议室系统、办公自动化系统、社区矫正管理系统、司法鉴定服务管理系统、行政许可审批系统、会议室音视频改造",
              ],
              src: "湖北省司法厅1.png",
            },
            {
              title: "湖北省商务厅",
              text1: [
                "机房系统工程（装饰装修系统、电气系统、新风系统、门禁系统、防雷接地系统、UPS不间断电源系统、消防报警系统）",
              ],
              src: "湖北省商务厅1.png",
            },
            {
              title: "湖北省卫生厅",
              text1: [
                "机房系统工程（装饰装修系统、供配电系统（含照明及应急照明）、视频监控系统、防雷、接地系统、消防报警系统、精密空调系统、UPS不间断电源系统、新风系统、门禁管理系统、弱电系统、场地监控系统、配套设施系统",
              ],
              src: "湖北省卫生厅1.png",
            },
            {
              title: "湖北省工商行政管理局及部分地市局",
              text1: [
                "建筑智能化弱电系统、网络系统集成、机房系统工程、安防监控系统、会议室音视频系统",
              ],
              src: "湖北省工商行政管理局及部分地市局1.png",
            },
            {
              title: "湖北省国家税务局",
              text: [
                "金税二期网络建设（网络改造、网络安全、视频会议）",
                "机房系统工程",
              ],
              src: "湖北省国家税务局1.png",
            },
            {
              title: "湖北省人力资源和社会保障厅",
              text1: [
                "建筑智能化弱电系统、网络系统集成、会议音视频系统、多功能电子考场系统、电气安装系统、安防监控系统、电子考场远程监控系统",
              ],
              src: "湖北省人力资源和社会保障厅1.png",
            },
            {
              title: "河南省自然资源厅",
              text: [
                "工程内容包括河南省国土空间基础信息平台和“一张图”实施监督信息系统专用基础设施采购安装调试。",
                "主要设备：存储、服务器、交换机、边界墙、云、数据库等。",
                "国土空间规划是国家空间发展的指南、可持续发展的空间蓝图，是各类开发保护建设活动的基础依据，通过本项目的建设，" +
                  "建立国土空间门户、数据资源分析应用、三维立体自然资源“一张图”、应用中心、服务中心、在线数据交换等功能，打造数据更全面、应用更广泛、共享更顺畅的国土空间基础信息平台。" +
                  "面向省政 府以及相关政府部门形成内容全量、更新及时、权威准确的省国土空间基础信息平台，有力支撑政府各部门科学规划城市发展，有效监管国土空间开发利用活动，提高政府管理决策水平。",
              ],
              src: "河南省自然资源厅1.png",
            },
            {
              title: "中共某市委办公厅",
              text1: [
                "屏蔽机房系统工程（装修系统、空调新风系统、门禁系统、电源防雷系统、弱电系统、监控系统、漏水监测报警系统、场地环境监控系统、供配电及UPS不间断、电源系统、消防系统）",
              ],
              src: "中共某市委办公厅1.png",
            },
            {
              title: "武汉市国家税务局",
              text: [
                "中南地区国税系统培训中心建筑智能化弱电系统、多媒体电教室系统、灯光音响系统、多媒体控制系统",
                "建筑智能化弱电系统、网络集成系统、网络安全系统（防火墙、物理隔离网闸安全系统）呼叫中心系统、门禁可视对讲系统、大屏显示系统、多媒体显示系统、软件开发系统",
                "视频会议系统、网络安全	防病毒系统、全景摄像视频监控系统",
              ],
              src: "武汉市国家税务局1.png",
            },
            {
              title: "武汉市人力资源和社会保障灾备中心",
              text: [
                "机房系统工程（装饰装修系统、供电系统、照明及应急系统、新风系统、精密空调系统、防雷系统、门禁管理系统、消防报警、灭火系统、电视监控系统、综合布线系统）",
                "运维服务（机房全系统运行保障）",
              ],
              src: "武汉市人力资源和社会保障灾备中心1.png",
            },
            {
              title: "湖北省黄石市公安局",
              text: [
                "指挥中心小间距LED大屏系统、监控分系统、应急调度指挥系统、控制系统、会议系统、内部对讲系统、综合布线系统。",
                "机房系统工程(监控中心及办公区装饰装修、电气安装系统、UPS电源系统、精密空调系统、防雷接地系统、环境监控系统、消防报警及灭火工程、弱电系统、监控大屏系统)。",
              ],
              src: "湖北省黄石市公安局1.png",
            },
            {
              title: "通城县公安局",
              text1: [
                "平安城市三期视频监控系统、大楼弱电信息系统，智能楼宇系统、智慧安防平台、公安110指挥中心、大数据中心",
              ],
              src: "通城县公安局1.png",
            },
            {
              title: "潜江市政府",
              text: [
                "xx机房位于新建大楼的一层和二层左侧。根据设计规划，其中两层楼共计面积为 1060平方。一层业务机房区域360平方：包含外网机房、传输机房、接入机房、调试间、电源室、网络机房、维修通道及缓冲区，二层业务机房和辅助办公区域700平方：包含主渠道机房、监控室、多功能办报室、值班室、会议室、卫生间、公共走道、办公室、职能室、学习（治谈）室、档案室、运维备件库。",
                "在大楼地下一层，设置UPS电池室，大楼室外安装集装箱式发电机组1套，位置待定。",
                "一层网络机房126平方、二层渠道机房29.4平方为屏蔽机房，采用焊接式屏蔽室，并安装配套电磁屏蔽门、电源、信号滤波器和屏蔽设备。",
                "一层、二层的机房内放置各种机柜设备54套、精密空调11台、配电控制柜6台及UPS设备1套；监控中心设置大屏1套；会议室设置大屏1套。",
              ],
              src: "潜江市政府1.png",
            },
            {
              title: "枣阳市重点人管控围栏热点采集项目",
              text: [
                "我司在枣阳市重点人管控项目中,根据整个项目的重点区域管控需求、以及各类场景下的管控目的 ,对辖区安装部署10套点采集设备。",
                "通过科学选点、合理布局,对主要区域进行了全面的覆盖。",
                "随着系统的不断优化,手机特征码数据、图像数据一并汇入大数据平台,现对重点人员的自动捕获、模型计算、实时预警、轨迹踉踪、动态管控;达到了来能报警 ,动知轨迹,走明去向,全程掌握＂的重点人员动态管控目标。平台方案依据地理、交通等环境定制需求。",
              ],
              src: "枣阳市重点人管控围栏热点采集项目1.png",
            },

            {
              title: "光谷消防特勤站指挥调度中心",
              text1: [
                "包括数据库安全管理、云平台及消防容灾中心 、应急联动指挥中心以及数据机房建设等施工图纸及工程量清单范围内包含的全部内容。",
              ],
              src: "光谷消防特勤站指挥调度中心3.jpeg",
            },
            {
              title: "郑州市第三看守所",
              text: [
                "数据中心封闭冷通道系统、数据中心UPS系统、数据中心空调、新风及排烟系统、动力环境监控。",
                "数据中心管理设备、电气工程、消防灭火系统、机房综合布线、机房装修。",
                "防雷接地工程等各个子系统的建设规划、方案设计、施工安装。",
              ],
              src: "郑州市第三看守所1.png",
            },
            {
              title: "湖北省恩施监狱",
              text1: [
                "监控系统、门禁系统、AB出入监管理系统、报警系统、智能监仓对讲系统、机房工程系统。",
              ],
              src: "湖北省恩施监狱1.png",
            },
          ],
        },
        {
          title: "智慧国防",
          children: [
            {
              title: "国家粮食和物资储备局某处",
              text1: ["综合布线、安防监控、武警智慧磐石等。"],
              src: "国家粮食和物资储备局373处1.png",
            },
            {
              title: "湖北省军区",
              text1: ["执勤监控平台系统、安防监控系统、机房工程系统。"],
              src: "湖北省军区1.png",
            },
            {
              title: "中国人民解放军95829部队",
              text1: [
                "机房工程系统、智慧营区系统、短波通信系统、计算机网络系统。",
              ],
              src: "中国人民解放军95829部队1.png",
            },
            {
              title: "武汉第二船舶设计研究所",
              text1: [
                "电磁屏蔽系统、机房装修工程、机房配电系统、机房空调、新风系统、机房消防系统、机房防雷接地系统、机房环境监控系统、机房安防系统、综合布线",
              ],
              src: "武汉第二船舶设计研究所.png",
            },
            {
              title: "中国舰船研究设计中心",
              text1: [
                "机房屏蔽壳体工程、机房装修工程、机房配电系统、机房防雷接地系统、机房精密空调系统、机房UPS系统、机房新风系统、机房综合布线系统、机房运行监控系统（含供配电监测、漏水检测、UPS监测、温湿度监测、空调系统监测、安防监控等）",
              ],
              src: "中国舰船研究设计中心.png",
            },
            {
              title: "中国船舶重工集团应急预警与救援装备股份有限公司",
              text1: [
                "设计服务、多媒体影片内容制作、多媒体互动控制软件、展厅多媒体大屏、展厅室内装饰工程",
              ],
              src: "中国船舶重工集团应急预警与救援装备股份有限公司.png",
            },
            {
              title: "中国人民解放军32020部队",
              text1: ["多媒体会议、无纸化办公系统、指挥调度系统"],
              src: "中国人民解放军32020部队.jpg",
            },
          ],
        },
        {
          title: "智慧医疗",
          children: [
            {
              title: "武汉市第一人民医院",
              text1: [
                "机房系统工程（基础建设系统、办公区装修系统、供配电系统、照明系统、防雷接地系统、新风系统 、门禁及可视对讲系统、消防报警系统、气体灭火系统、视频监控系统、场地监控系统、精密空调系统、UPS系统、弱电系统)。",
              ],
              src: "武汉市第一人民医院1.png",
            },
            {
              title: "恩施土家族苗族自治州中心医院",
              text1: [
                "建筑智能化、安防监控、综合布线、网络系统集成、机房建设、有线电视、停车管理系统等。",
              ],
              src: "恩施土家族苗族自治州中心医院1.png",
            },
            {
              title: "武汉大学人民医院",
              text: [
                "机房系统工程(包括装修系统工程、供配电系统工程、防雷、接地系统工程、新风系统工程、消防报警系统工程、气体自动灭火系统工程、场地监控系统工程、精密空调系统工程、UPS系统工程、综合布线系统工程、KVM系统工程、机柜系统工程、网络设备)。",
                "办公区空调系统工程、大厅景观灯。",
              ],
              src: "武汉大学人民医院1.png",
            },
            {
              title: "雷神山医院",
              text1: [
                "6天6夜，60名员工，奋战在—线，完成6个病区的医疗系统的建设综合布线系统、弱电信息机房、医护对讲系统、监控报警系统、WIFI覆盖",
              ],
              src: "雷神山医院1.jpg",
            },
            {
              title: "华中科技大学同济医学院附属同济医院",
              text1: [
                "办公室装修系统工程、电气设备系统工程、综合布线系统工程、机房系统工程（包括装修系统工程、防雷接地系统工程、消防报警系统工程、气体自动灭火系统工程、安防视频监控系统工程、精密空调系统工程、机柜及KVM系统工程）；21楼拆除工程，办公区装修系统工程、电气设备工程系统工程、综合布线系统工程、会议室投影系统工程",
              ],
              src: "华中科技大学同济医学院附属同济医院1.png",
            },
            {
              title: "荣军医院康复养老中心",
              text1: [
                "荣军医院康复养老中心弱电建设项目，其主要建设范围包括大楼综合布线、室内公共广播室外景观广播、IP智能电话大楼IPTV电视系统、门禁监控、信息化网络安全建设、分布式存储系统、智慧医疗系统、中心机房建设、机房精密空调及UPS配电系统、电梯互动对讲系统、信息发布系统、大楼BA智能控制系统、无线覆盖系统、地下室停车引导系统、排队叫号系统、音视频会议系统等建设",
              ],
              src: "荣军医院康复养老中心1.jpg",
            },
            {
              title: "宜昌市第二人民医院",
              text: [
                "智能建筑体系（综合布线系统、有线电视系统、计算机网络系统、公共广播系统、无线网系统、安全防范系统、巡更系统、综合机房工程、电话通讯系统、防盗报警系统）",
                "优质医疗体系（停车管理系统、基准时钟系统、多媒体会议、手术示教系统、信息发布系统、排队叫号系统）",
              ],
              src: "宜昌市第二人民医院.png",
            },
          ],
        },
        {
          title: "智慧交通",
          children: [
            {
              title: "武汉市公安局交通管理局",
              text: [
                "第七届世界军人运动会信号机项目、机房系统工程、红色警营展馆信息化。",
                "建筑智能化弱电系统、有线电视系统、安防监控系统、信息发布系统、排队叫号系统、门禁管理系统（含停车管理）、会议系统（电视电话会议室、大会议室）、电子巡更系统",
              ],
              src: "武汉市公安局交通管理局1.png",
            },

            {
              title: "武汉市城市路桥收费管理中心",
              text1: [
                "机房系统工程（监控中心及办公区装饰装修、电气安装系统、UPS电源系统、精密空调系统、防雷接地系统、环境监控系统、消防报警及灭火工程、弱电系统、监控大屏系统）",
              ],
              src: "武汉市城市路桥收费管理中心1.png",
            },

            // {
            //   title: "武汉市公安局交通管理局洪山大队",
            //   text: [
            //     "建筑智能化弱电系统、有线电视系统、安防监控系统、信息发布系统、排队叫号系统、门禁管理系统（含停车管理）、会议系统（电视电话会议室、大会议室）、电子巡更系统",
            //   ],
            //   src: "武汉市公安局交通管理局洪山大队1.png",
            // },
            {
              title:
                "武汉地铁集团-武汉市轨道交通7号线一期三阳路风塔配套综合开发项目一期工程弱电系统施工",
              text1: [
                "视频监控系统、门禁管理系统、巡更管理系统、综合布线系统、综合管路系统、弱电UPS系统、停车场管理系统",
              ],
              src: "武汉市轨道交通7号线.png",
            },
            {
              title:
                "武汉地铁集团-武汉市轨道交通8号线赵家条配套用房弱电系统工程施工",
              text1: [
                "视频监控系统、门禁管理系统、综合布线系统、机电设备监控系统、广播系统、会议系统、停车场管理系统等。",
              ],
              src: "武汉市轨道交通8号线.png",
            },
          ],
        },
        {
          title: "智慧教育",
          children: [
            {
              title: "华中师范大学",
              text: [
                "建筑智能化弱电系统、数字化校园中心机房系统工程。",
                "(空调新风系统、门禁系统、电源防雷系统、混水监测报警系统、场地环境监控系统、配电系统、UPS系统)。",
              ],
              src: "华中师范大学1.png",
            },
            {
              title: "中科院武汉分院",
              text: [
                "建筑智能化弱电系统、监控报警系统。",
                "网络系统集成、网络管理软件。",
              ],
              src: "中科院武汉分院1.png",
            },
            {
              title: "华中科技大学",
              text1: [
                "数字化校园机房系统工程(空调新风系统、门禁系统、电源防雷系统、漏水监测报警系统、场地环境监控系统、配电系统、UPS系统)。",
              ],
              src: "华中科技大学1.png",
            },
            {
              title: "湖北省教育厅",
              text1: [
                "机房系统工程（空调新风系统、门禁系统、电源防雷系统、弱电系统、电视监控系统、混水监测报警系统、场地环境监控系统、视频会议室系统、供配电系统、UPS系统、消防系统）",
              ],
              src: "湖北省教育厅1.png",
            },
            {
              title: "长江大学",
              text1: [
                "智慧校国网中心机房系统工程（模块化机柜、空调新风系统、门禁系统、电源防雷系统、漏水监测报警系统、场地环境监控系统、配电系统、视频会议系统、UPS系统、会议室及音响设备系统）",
              ],
              src: "长江大学1.png",
            },
            {
              title: "湖北省社会主义学院",
              text1: [
                "基于安可信创环境的应用集成与服务",
              ],
              src: "湖北省社会主义学院.png",
            },
            {
              title: "安徽颍上县教育局",
              text1: [
                "校园网络系统、综合布线系统、综合管路、机房工程、校园安防监控系统、广播系统、录播教室、报告厅、合班教室、云机房综合教室、信息发布系统建设",
              ],
              src: "安徽颍上县教育局.png",
            },
          ],
        },
        {
          title: "智慧园区",
          children: [
            {
              title: "腾讯武汉研发中心",
              text: [
                "建筑智能化弱电系统、视频监控系统、能源管理系统、门禁系统、访客系统、报警系统、停车管理系统、信息发布系统。",
                "楼宇自控系统、IBMS系统、会议系统、大数据中心系统工程",
              ],
              src: "深圳市腾讯计算机系统有限公司1.png",
            },
            {
              title: "中冶南方工程技术有限公司",
              text: [
                "建筑智能化弱电系统。",
                "机房系统工程(装饰装修系统、门禁系统、弱电系统、消防报警系统工程、消防灭火系统、安防监控系统、防雷系统、UPS不间断电源系统)。",
              ],
              src: "中冶南方工程技术有限公司1.png",
            },
            {
              title: "中国化学工程第六建设有限公司",
              text: [
                "建筑智能化弱电系统、数据网络（含云系统）。",
                "安全防范系统、智能一卡通系统、多媒体会议系统。",
                "信息发布系统、楼宇自控系统、机房工程系统、公共广播系统、有线电视系统。",
              ],
              src: "中国化学工程第六建设有限公司1.png",
            },
            {
              title: "武汉软件产业基地",
              text1: [
                "建筑智能化弱电系统、机房系统工程（电气系统、UPS不间断电源系统、空调系统、新风系统防雷系统、接地系统）",
              ],
              src: "武汉软件产业基地1.png",
            },
            {
              title: "五环科技股份有限公司",
              text1: [
                "建筑智能化弱电系统、安防监控系统、网络系统集成、网络存储系统、视频会议系统、智慧停车管理系统、音视频可视对讲系统、机房系统工程",
              ],
              src: "五环科技股份有限公司1.png",
            },
            {
              title: "中建三局宜昌建设发展有限公司",
              text1: [
                "视频监控系统、门禁系统、信息发布系统、可视对讲系统、无线对讲功能、停车管理收费系统、电子巡更、防盗报警系统、背景音乐系统、综合布线和宽带接入系统、计算机网络系统、电梯五方通话系统等等。",
              ],
              src: "中建三局宜昌建设发展有限公司1.png",
            },
            {
              title: "武汉梦时代广场",
              text1: [
                "武汉梦时代广场集零售、科技馆、海洋馆、冰雪乐园、主题乐园千—体，打造华中乃至全国定位较高、规模最大的商业零售综合体。总投资超120亿元，计划2022年竣工交付，是武汉市“十三五”重点工程项目。项目总用地面积约136亩，总建筑面积59.93万平方米，建筑高度85.4米，地下4层，地上9层规划地下机动车停车位7287个、非机动车位1412个。本项目工程智能化系统工程二包项目包括：公共广播与背景音乐系统、紧急报警系统、视频监控系统、出入口及IC卡系统（门禁、考勤、消费）、消控中心装修及配线间配电系统、电梯通讯系统、管线桥架及系统（含室外）等部分。",
              ],
              src: "武汉梦时代广场1.jpg",
            },
            {
              title: "中建三局新时代商务中心",
              text1: [
                "中建三局新时代商务中心弱电建设项目，其主要建设范围包括：大楼综合布线、楼宇安全、公共广播、IP智能电话、门禁监控、信息化网络安全建设、音视频会议系统建设，整个工程从确定项目立项后，前期展开需求调研，确认客户建设需求，与客户多次召开需求讨论会议，确定各子系统建设要求及功能需求，汇总专项报告，并且针对中建三局商秘安全网络安全建设提出的国家等级保护测评2.0标准为核心来规划各类配置方案，包括防火墙、行为审计、防病毒软件等软硬件设备的选型，来进行多方面的讨论和评审，最终与客户以及中建总局安全部门—同确定最终的产品选型和功能应用。在参与招投标并确认中标身份后，公司按照信息化安全集成的工程实施流程及规范，逐步开展各项子系统的建设，特别是在信息化安全建设及等保2.0评测过程中，与测评单位展开多项压力测试，对外部入侵、数据分包分析及内部权限调用查看等几个重点安全环节，进行了多方面的探讨和实机模拟入侵测试，经过1个月的自检及试运行后，排查及整改自检及试运行过程中发现的问题，确保整个信息化建设工程交付后的稳定性和安全性。最终提交验收申请，获得客户及第三放安全评审机构及工程审计监理单位的认可后，验收交付。整个工程进入售后运维阶段。",
              ],
              src: "中建三局新时代商务中心1.png",
            },
            {
              title: "浙江宁波江北膜幻动力小镇客厅建设项目",
              text: [
                "项目位于宁波（江北 ）高新技术产业园区，总规划面积约3平方公里。北邻余北快速路，南靠 S61 省道和机场高架，西面南北连接线，北接庆丰路。",
                "小镇以膜材料、高端装备制造为主导产业，着力发展动力光电膜材料集聚区以及动力光电膜材料衍生产业集聚区。",
              ],
              src: "浙江宁波江北膜幻动力小镇客厅建设项目1.png",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.inTitle = this.$store.state.title;
    this.activeTitle = this.$store.state.name;
    const data = this.getCurrent(
      this.$store.state.title,
      this.$store.state.name
    );
    this.$emit("getData", data);
  },
  watch: {
    "$store.state.title"(newTitle) {
      const data = this.getCurrent(newTitle, this.$store.state.name);
      this.$emit("getData", data);
    },
    "$store.state.name"(value) {
      this.activeTitle = value;
    },
  },
  methods: {
    handleSelect(index, indexPath) {
      store.dispatch("changeDispatch", indexPath[0]);
      store.dispatch("changeDispatchTitle", index);
      const data = this.getCurrent(indexPath[0], index);
      this.$emit("getData", data);
    },
    getCurrent(index, value) {
      this.inTitle = index;
      let data = [];
      this.list.map((v) => {
        if (v.title === index) {
          if (value) {
            this.activeTitle = value;
            v.children.map((text) => {
              if (text.title === value) {
                data = JSON.parse(JSON.stringify(text));
              }
            });
          } else {
            data = JSON.parse(JSON.stringify(v.children?.[0] || {}));
            this.activeTitle = data.title;
          }
        }
      });
      return data;
    },
  },
};
</script>

<style scoped lang="less">
.content {
}
.el-menu-item {
  //font-size: 20px !important;
  color: #000000;
  height: 4rem;
  display: flex;
  align-items: center;
}
.el-menu {
  border-right: 2px solid #e6e6e6;
}
.el-submenu {
  width: 39rem;
  border-bottom: 1px solid #e6e6e6;
}
//.el-menu-item:hover{
//   background-color: white;
//}
.son-title {
  padding-left: 5rem;
  .son-text {
    padding: 1rem 1.5rem;
    //box-sizing: border-box;
    line-height: 1;
	font-size:1.5rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	height: 2.5rem;
	width:28rem;
  }
}
.son-text-active {
  color: #000000;
  background-color: #409eff;
}
.son-text:hover {
  color: #000000;
  background-color: #409eff;
  // color: #8c8c8c;
  // background-color: #f3f3f3;
}

.el-menu-item:hover {
  outline: 0 !important;
  background-color: transparent !important;
}
.el-menu-item.is-active {
  background-color: transparent !important;
}
</style>
