<template>
	<div>
      <Header />
      <Wall title="典型案例" english="Typical Case" />
      <div class="content">
        <LeftNavigation @getData="getData" />
        <div class="main-content">
          <div class="title">{{ data.title }}</div>
          <div class="head">{{ data.head || "工程内容" }}</div>
          <div class="text1">
            <p v-for="(item, key) in data.text1 || []" :key="key">
              <!-- <span>{{ chIndex[key] }}、</span> -->
              {{ item }}
            </p>
          </div>
          <div class="text">
            <p v-for="(item, key) in data.text || []" :key="key">
              <span>{{ chIndex[key] }}、</span>
              {{ item }}
            </p>
          </div>
          <div class="img">
          <img
            v-if="data.src"
            :src="require(`../assets/case/${data.src}`)"
            alt=""
          />
        </div>
        </div>
      </div>
      <Footer />
	</div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Wall from "@/views/components/Wall.vue";
import LeftNavigation from "@/views/components/LeftNavigation.vue";
export default {
  components: {
    Wall,
    Header,
    Footer,
    LeftNavigation,
  },
  data() {
    return {
      chIndex: ["一", "二", "三", "四", "五", "六", "七", "八", "九"],
      data: {
        title: "光谷消防特勤站指挥调度中心",
        text: [
          "包括数据库安全管理、云平台及消防容灾中心 、应急联动指挥中心以及数据机房建设等施工图纸及工程量清单范围内包含的全部内容。",
        ],
        src: "光谷消防特勤站指挥调度中心3.jpeg",
      },
    };
  },
  methods: {
    getData(data) {
      this.data = data;
    },
  },
};
</script>

<style scoped lang="less">
.content {
  display: flex;
  padding-top: 2rem;
  .main-content {
    width: 70%;
    padding: 2rem 4rem 4rem 4rem;
    .title {
      font-size: 2.4rem;
      //font-weight: 500;
      text-align: center;
    }
    .head {
      font-size: 2rem;
      padding: 1.2rem 0 2rem 2rem;
    }
    .text {
      padding-left: 6rem;
      box-sizing: border-box;
	  font-size:1.5rem;
      // line-height: 40px;
    }
    .text1{
      padding-left: 6rem;
      box-sizing: border-box;
      text-indent: 2em;
	  font-size:1.5rem;
    }
    .img{
      display: flex;
      justify-content: center;
      width: 100%;
    }
    p {
      color: #6c6c6c;
      padding: 0.2rem 0;
      letter-spacing: 0.1rem;
      line-height: 1.5;
	  font-size:1.5rem;
    }
    img {
      height: auto;
      width: 75%;
      margin-top: 2rem;
    }
  }
}
</style>
